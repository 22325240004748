<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <v-card id="dialog" class="TF type2" width="100%">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage" style="text-align: center">
              SCREENING QUESTIONNAIRE COVID-19 and Monkeypox:
            </div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 3" step="3"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 4" step="4"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items class="T-size-16">
                  <!-- Start step 1 -->
                  <v-stepper-content step="1" ref="focusStep1">
                    <div class="b-contentstep" style="color: white">
                      <div class="box-S4">
                        <div class="B-passenger">
                          <p>
                            <b>An outbreak of COVID-19 and Monkeypox requires
                              early and effective detection of suspected cases
                              to limit the risk of exposure to others. We are
                              kindly requesting you to complete the following
                              questions and to have your temperature checked by
                              us.</b>
                          </p>
                          <p>
                            การระบาดของโรคติดเชื้อโควิด19 และฝีดาษลิง
                            จำเป็นต้องมีการตรวจหาผู้ต้องสงสัยในระยะแรกอย่างมีประสิทธิภาพ
                            เพื่อจำกัดความเสี่ยงในการสัมผัสกับผู้อื่น
                            เราขอให้คุณกรุณาตอบคำถามต่อไปนี้และให้เราตรวจสอบอุณหภูมิร่างกายของคุณ
                          </p>
                          <div class="b-person">
                            <div class="b-display">
                              <div class="B-display"></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name T-size-14">
                                {{ u_firstname }} {{ u_lastname }}
                              </p>
                              <div class="t-company">
                                Company (บริษัท) : {{ c_name }}
                              </div>
                              <div class="t-company">
                                Field Name (พื้นที่ที่ไปปฏิบัติงาน) : -
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="box-S4">
                      <div class="b-btnSave">
                        <v-btn class="theme-btn-even btn-save Bsize100" @click="GotoStepDialog2()">
                          <span class="T-size-18">NEXT</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-stepper-content>
                  <!-- End step 1 -->

                  <!-- Start step 2 -->
                  <v-stepper-content step="2" ref="focusStep2">
                    <div class="b-contentstep" style="color: white; padding-top: 10px">
                      <div class="box-S4">
                        <p>
                          <b>1. Do you currently have any of the following
                            symptoms within the past 7 days? Yes/ No</b>
                          คุณมีอาการดังต่อไปนี้หรือไม่ ในช่วง 7 วันที่ผ่านมา; มี
                          / ไม่มี
                        </p>
                      </div>
                      <div class="box-s4">
                        <p>Fever/Chills มีไข้/อาการหนาวสั่น</p>
                        <v-radio-group v-model="mcf_1_a" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>
                        <p>Shortness of breath หายใจถี่</p>
                        <v-radio-group v-model="mcf_1_b" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Diarrhea ภาวะท้องเสีย/อุจจาระร่วง</p>
                        <v-radio-group v-model="mcf_1_c" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>
                          Ageusia (loss of taste) สูญเสียการรับรสโดยสิ้นเชิง
                        </p>
                        <v-radio-group v-model="mcf_1_d" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Runny nose น้ำมูกไหล</p>
                        <v-radio-group v-model="mcf_1_e" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Cough อาการไอ</p>
                        <v-radio-group v-model="mcf_1_f" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Difficulty breathing หายใจลำบาก</p>
                        <v-radio-group v-model="mcf_1_g" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>
                          Anosmia (loss of smell) สูญเสียการได้กลิ่น
                          (จมูกไม่ได้กลิ่น)
                        </p>
                        <v-radio-group v-model="mcf_1_h" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Pink eye ตาแดง</p>
                        <v-radio-group v-model="mcf_1_i" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Skin rashes มีผื่นขึ้น</p>
                        <v-radio-group v-model="mcf_1_j" v-on:change="CheckStep2()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        <p>
                          <b>2. Did you have close contact or spend time within 2 meters distance with a person who has been confirmed or under investigation of Covid-19 infection within the past 7 days?  Yes/ No
                          <br />If yes, please provide more details about close contact person that tests positive for Covid-19</b>
                          คุณมีการติดต่อพูดคุยใกล้ชิดกับบุคคลที่มีการติดเชื้อโควิด19 หรือผู้ที่อยู่ในระหว่างการตรวจสอบหาภาวะติดเชื้อโควิด19 ในระยะเวลา 7 วันที่ผ่านมา หรือไม่; ใช่/ ไม่ใช่
                          <br />หากใช่ โปรดระบุรายละเอียดการสัมผัสใกล้ชิดกับผู้ป่วยโควิด19
                        </p>
                      </div>
                      <v-radio-group v-model="mcf_2" v-on:change="CheckStep2()">
                        <v-radio label="Yes ใช่" value="true"></v-radio>
                        <v-radio label="No ไม่ใช่" value="false"></v-radio>
                      </v-radio-group>
                      <div v-show="mcf_2 == 'true'">
                        โปรดระบุรายละเอียดการสัมผัสใกล้ชิดกับผู้ป่วยโควิด19
                        <v-text-field class="hideMessage" label="โปรดระบุ" @input="CheckStep2()" v-model="mcf_2_remark">
                        </v-text-field>
                      </div>

                      <div class="box-S4">
                        <p>
                          <b>3.	Did you have history of COVID-19 disease? Yes/ No
                          <br />If yes, please provide the details about the treatment</b>
                          คุณเคยมีประวัติป่วยเป็นโรคติดเชื้อโควิด19 หรือไม่; ใช่/ ไม่ใช่
                          <br />หากคุณเคยมีประวัติป่วยเป็นโรคติดเชื้อโควิด19 โปรดระบุรายละเอียดการรักษาตัว
                        </p>
                      </div>
                      <v-radio-group v-model="mcf_3" v-on:change="CheckStep2()">
                        <v-radio label="Yes ใช่" value="true"></v-radio>
                        <v-radio label="No ไม่ใช่" value="false"></v-radio>
                      </v-radio-group>
                      <div v-show="mcf_3 == 'true'">
                        โปรดระบุรายละเอียดการรักษาตัว
                        <v-text-field class="hideMessage" label="โปรดระบุ" @input="CheckStep2()" v-model="mcf_3_remark">
                        </v-text-field>
                      </div>

                      <div class="box-S4">
                        <p>
                          <b>4. Have you received the COVID-19 vaccine? Yes/No</b>
                          คุณได้รับวัคซีนไวรัสโคโรนา 2019 มาแล้วหรือไม่; ใช่/ ไม่ใช่
                        </p>
                      </div>
                      <v-radio-group v-model="mcf_4" v-on:change="CheckStep2()">
                        <v-radio label="Completed 1 dose ได้รับแล้ว 1 เข็ม" value="1"></v-radio>
                        <v-radio label="Completed 2 dose ได้รับแล้ว 2 เข็ม" value="2"></v-radio>
                        <v-radio label="Completed 3 dose ได้รับแล้ว 3 เข็ม" value="3"></v-radio>
                        <v-radio label="Completed 4 dose ได้รับแล้ว 4 เข็ม" value="4"></v-radio>
                        <v-radio label="Completed > 4 dose ได้รับแล้วมากกว่า 4 เข็ม" value="5"></v-radio>
                        <v-radio label="None ยังไม่ได้วัคซีน" value="0"></v-radio>
                      </v-radio-group>
                      
                      <div class="box-S4">
                        <p>
                          <b>5.	If yes, please details the name of vaccine, Dose (Booster dose) and receive date.</b>
                          หากใช่ โปรดระบุยี่ห้อของวัคซีน และวันที่ได้รับ การฉีด (เฉพาะบูสเตอร์โดส)
                        </p>
                        Dropdow
                      </div>
                      <v-radio-group v-model="mcf_5" v-on:change="CheckStep2()">
                        <v-radio label="Yes ใช่" value="true"></v-radio>
                        <v-radio label="No ไม่ใช่" value="false"></v-radio>
                      </v-radio-group>
                      <div v-show="mcf_5 == 'true'">     
                          <div class="b-nameLocation flex-between-center">
                            <div class="box-S2 noPadding">
                              <div class="b-neme">
                                Booster dose
                              </div>
                            </div>
                          </div>
                          <div class="b-inputData box-S4 noPadding">
                            <div class="box-S4 flex-between noPadding">
                              <div class="box-S2 noPadding">
                                <v-select :items="itemsListVaccine" item-text="vc_name" item-value="vc_id"
                                  label="Name of vaccine ยี่ห้อวัคซีน" v-model="mcf_5_vaccine_name" return-object>
                                </v-select>
                              </div>
                              <div class="box-S2 noPadding">
                                <v-menu v-model="menuselect_mcf_5_vaccine_date" :close-on-content-click="false"
                                  transition="scale-transition" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field class="hideMessage input-date" v-model="mcf_5_vaccine_date"
                                      label="Received date วันที่ได้รับการฉีด" readonly v-on="on"
                                      append-icon="mdi-calendar-range"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="mcf_5_vaccine_date" @input="menuselect_mcf_5_vaccine_date = false
                                  "></v-date-picker>
                                </v-menu>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div class="box-S4">
                        <p>
                          <b>6. Did you visit the place have outbreak of Monkeypox? (Central and West Africa, particularly the Democratic Republic of the Congo (DRC) and Nigeria)? Yes/ No 
                          <br />If yes, please provide details confirm place or area you visited;</b>
                          คุณได้เดินทางไปยังพื้นที่ที่มีการแพร่ระบาดของโรคฝีดาษลิงหรือไม่ (ประเทศแถบแอฟริกา, คองโก หรือไนจีเรีย); ใช่/ ไม่ใช่
                          <br />หากใช่ หากใช่โปรดระบุสถานที่ที่ท่านเดินทางไป;
                        </p>
                      </div>

                      <v-radio-group v-model="mcf_6" v-on:change="CheckStep2()">
                        <v-radio label="Yes ใช่" value="true"></v-radio>
                        <v-radio label="No ไม่ใช่" value="false"></v-radio>
                      </v-radio-group>
                      <div v-show="mcf_6 == 'true'">
                        โปรดระบุสถานที่ที่ท่านเดินทางไป
                        <v-text-field class="hideMessage" label="โปรดระบุ" v-model="mcf_6_remark" @input="CheckStep2()">
                        </v-text-field>
                      </div>
                    </div>

                    <div class="line"></div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(1)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="step2" @click="GotoStepDialog3()">
                        <span class="T-size-18">NEXT</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 2-->

                  <!-- Start step 3 -->
                  <v-stepper-content step="3" ref="focusStep3">
                    <div class="b-contentstep" style="color: white; padding-top: 10px">
                      <div class="box-S4">
                        <p>
                          <b>7. Do you currently have any of the following symptoms? Yes/ No</b>
                          คุณมีอาการต่อไปนี้หรือไม่ เลือกทั้งหมดที่เกี่ยวข้อง: มี / ไม่มี
                        </p>
                      </div>

                      <div class="box-s4">
                        <p>Headache ปวดศีรษะ</p>
                        <v-radio-group v-model="mcf_7_a" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>
                        <p>Fever (T > 38.5 C) มีไข้ (T>38.5 C)</p>
                        <v-radio-group v-model="mcf_7_b" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Lymphadenopathy ต่อมน้ำเหลืองโต</p>
                        <v-radio-group v-model="mcf_7_c" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>
                          Myalgia (muscle and body aches) ปวดเมื่อยกล้ามเนื้อ
                        </p>
                        <v-radio-group v-model="mcf_7_d" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Back pain ปวดหลัง</p>
                        <v-radio-group v-model="mcf_7_e" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Asthenia (profound weakness) กล้ามเนื้ออ่อนแรง</p>
                        <v-radio-group v-model="mcf_7_f" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Unexplained acute rash ผื่นที่ไม่ทราบสาเหตุ</p>
                        <v-radio-group v-model="mcf_7_g" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>

                        <p>Other symptoms อาการอื่นๆ</p>
                        <v-radio-group v-model="mcf_7_h" v-on:change="CheckStep3()">
                          <v-radio label="Yes มี" value="true"></v-radio>
                          <v-radio label="No ไม่มี" value="false"></v-radio>
                        </v-radio-group>
                      </div>

                      <div class="box-S4">
                        <p>
                          <b>8. Did you have close contact with a person who has been confirmed of Monkeypox? Yes/ No
                          <br />If yes, please provide more details</b> คุณมีการสัมผัสใกล้ชิดกับบุคคลที่มีการติดเชื้อโรคฝีดาษลิงหรือไม่;ใช่ / ไม่ใช่
                          <br />หากใช่ โปรดระบุรายละเอียดการสัมผัสใกล้ชิดกับผู้ติดเชื้อ
                        </p>
                      </div>
                      <v-radio-group v-model="mcf_8" v-on:change="CheckStep3()">
                        <v-radio label="Yes ใช่" value="true"></v-radio>
                        <v-radio label="No ไม่ใช่" value="false"></v-radio>
                      </v-radio-group>
                      <div v-show="mcf_8 == 'true'">
                        โปรดระบุรายละเอียดการสัมผัสใกล้ชิดกับผู้ติดเชื้อ
                        <v-text-field class="hideMessage" label="โปรดระบุ" v-model="mcf_8_remark"
                          @input="CheckStep3()">
                        </v-text-field>
                      </div>
                    </div>

                    <div class="line"></div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(2)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="step3" @click="GotoStepDialog4()">
                        <span class="T-size-18">NEXT</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 3-->

                  <!-- Start step 4 -->
                  <v-stepper-content step="4" ref="focusStep4">
                    <div class="b-contentstep" style="color: white">
                      <div class="box-S4">
                        <div class="B-passenger">
                          <v-checkbox v-model="consented"
                            :label="`Note: 	 I declare that the provided information is true, and authorized to Valeura Energy (Thailand), for further investigation หมายเหตุ: ข้าพเจ้าขอรับรองว่าข้อมูลที่รายงานข้างต้นเป็นความจริงทุกประการ และอนุญาตให้ใช้ข้อมูลเบื้องต้นกับ บริษัท แวลูร่า เอนเนอร์ยี่ ประเทศไทย ในการพิจารณาลำดับถัดไป`">
                          </v-checkbox>
                          <div style="padding-left: 30px">
                            <p>
                              -
                              <span style="font-weight: bold">Failing to report accurate details may be
                                subjected to disciplinary action</span><br /><span
                                style="padding-left: 11px">หากแจ้งข้อมูลเป็นเท็จหรือไม่ครบถ้วน
                                อาจมีความผิดทางวินัย</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="BackStepDialog(3)">
                        <span class="T-size-18">BACK</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text v-show="consented"
                        @click="dialogConfirm = true">
                        <span class="T-size-18">CONFIRM</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- End step 4-->
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialogConfirm" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog save">
              <div class="t-title">Confirm Information</div>
              <div class="t-des"></div>
              <div class="box-S4"></div>
            </div>
            <div class="box-btn">
              <v-btn @click="dialogConfirm = false" class="theme-btn-even btn-cancel Bsize100" text>
                <span class="T-size-18">NO</span>
              </v-btn>
              <v-btn @click="SaveToDB()" class="theme-btn-even btn-save Bsize100" text>
                <span class="T-size-18">YES</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSaveComplete" persistent max-width="300">
        <v-card id="dialog" class="TF type1">
          <div class="b-content">
            <div class="B-dialog confirm">
              <div class="t-title">Save Completed</div>
            </div>
            <div class="box-btn">
              <v-btn class="theme-btn-even btn-ok Bsize100" style="width: 100%;" text :to="{
                name: 'MubadalaForCustomerEForm3',
                params: { id: this.$route.params.id },
              }">
                <span class="T-size-18">OK</span>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { format } from "date-fns";

export default {
  data: () => ({
    stepdialog: 1,
    u_firstname: "",
    u_lastname: "",
    c_name: "",
    o_name: "",

    //Step II Start
    step2: false,
    mcf_1_a: null,
    mcf_1_b: null,
    mcf_1_c: null,
    mcf_1_d: null,
    mcf_1_e: null,
    mcf_1_f: null,
    mcf_1_g: null,
    mcf_1_h: null,
    mcf_1_i: null,
    mcf_1_j: null,
    mcf_2: null,
    mcf_2_remark: "",
    mcf_3: null,
    mcf_3_remark: "",
    mcf_4: null,
    //#5
    mcf_5: null,
    mcf_5_vaccine_name:"",
    mcf_5_vaccine_date: format(new Date(), "yyyy-MM-dd"),
    menuselect_mcf_5_vaccine_date: false,
    arrVaccine: [],
    itemsListVaccine: [
      {
        vc_id: 1,
        vc_name: "Sinovac",
      },
      {
        vc_id: 2,
        vc_name: "Sinopharm",
      },
      {
        vc_id: 3,
        vc_name: "AstraZeneca",
      },
      {
        vc_id: 4,
        vc_name: "Pfizer",
      },
      {
        vc_id: 5,
        vc_name: "Moderna",
      },
      {
        vc_id: 6,
        vc_name: "Johnson & Johnson",
      },
      {
        vc_id: 7,
        vc_name: "Covaxin",
      },
      {
        vc_id: 8,
        vc_name: "Sputnik V",
      },
      {
        vc_id: 9,
        vc_name: "Biontech",
      },
    ],
    mcfd_vaccine_name: {},
    //#5

    //Step III Start
    step3: false,
    mcf_6: null,
    mcf_6_remark: "",
    //Step II End
    mcf_7_a: null,
    mcf_7_b: null,
    mcf_7_c: null,
    mcf_7_d: null,
    mcf_7_e: null,
    mcf_7_f: null,
    mcf_7_g: null,
    mcf_7_h: null,
    mcf_8: null,
    mcf_8_remark: "",
    //Step III End

    consented: false,

    dialogConfirm: false,
    dialogSaveComplete: false,
  }),

  components: {},
  async mounted() {
    let q = {};
    q.p_id = this.$route.params.id;
    let res = await feathersClientUOA
      .service("viewcheckinpassenger-2")
      .find({ query: q });
    let user = res.data[0];
    
    // if (user.mcf_status != 'No Data') {
    //   if (user.mcf_status == 'No Data') {
    //     this.$router.push({
    //       path: `/mubadalaforcustomereform3/${this.$route.params.id}`,
    //     });
    //   }
    //   else if (user.mcf_pass_signature == null || user.mhf_pass_singnature == null || user.maf_pass_signature == null) {
    //     this.$router.push({
    //       path: `/MubadalaForCustomerEFormSignature/${this.$route.params.id}`,
    //     });
    //   }
    //   else
    //   {
    //     this.$router.push({
    //       path: `/mubadalaforcustomereformthankyou`,
    //     });
    //   }
    // }
    this.u_firstname = user.u_firstname;
    this.u_lastname = user.u_lastname;
    this.c_name = user.c_name;
  },
  methods: {
    CheckStep2() {
      let case1 = false;
      let case2 = false;
      let case3 = false;
      let case4 = false;
      let case5 = false;
      let case6 = false;

      if (
        this.mcf_1_a != null &&
        this.mcf_1_b != null &&
        this.mcf_1_c != null &&
        this.mcf_1_d != null &&
        this.mcf_1_e != null &&
        this.mcf_1_f != null &&
        this.mcf_1_g != null &&
        this.mcf_1_h != null &&
        this.mcf_1_i != null &&
        this.mcf_1_j != null
      ) {
        case1 = true;
      } else {
        case1 = false;
      }

      if (this.mcf_2 != null) {
        if (this.mcf_2 == "true") {
          if (this.mcf_2_remark != "") {
            case2 = true;
          }
        } else {
          this.mcf_2_remark = "";
          case2 = true;
        }
      }

      if (this.mcf_3 != null) {
        if (this.mcf_3 == "true") {
          if (this.mcf_3_remark != "") {
            case3 = true;
          }
        } else {
          this.mcf_3_remark = "";
          case3 = true;
        }
      }

      if(this.mcf_4 != null){
        case4 = true;
      }

      if (this.mcf_5 != null) {
        if(this.mcf_5 == 'true'){
          if(this.mcf_5 != null && this.mcf_5_vaccine_date != null){
            case5 = true;
          }
        }else if(this.mcf_5 == 'false'){
          this.mcf_5_vaccine_name = "";
          this.mcf_5_vaccine_date = null;
          this.menuselect_mcf_5_vaccine_date = false;
          case5 = true;
        }
      }

      if (this.mcf_6 != null) {
        if (this.mcf_6 == "true") {
          if (this.mcf_6_remark != "") {
            case6 = true;
          }
        } else {
          this.mcf_6_remark = "";
          case6 = true;
        }
      }

      if (case1 && case2 && case3 && case4 && case5 && case6) {
        this.step2 = true;
      } else {
        this.step2 = false;
      }
    },
    GotoStepDialog2() {
      this.stepdialog = 2;
      this.GotoFocusStep('focusStep2')
    },

    CheckStep3() {
      let case7 = false;
      let case8 = false;
      if (
        this.mcf_7_a != null &&
        this.mcf_7_b != null &&
        this.mcf_7_c != null &&
        this.mcf_7_d != null &&
        this.mcf_7_e != null &&
        this.mcf_7_f != null &&
        this.mcf_7_g != null &&
        this.mcf_7_h != null
      ) {
        case7 = true;
      } else {
        case7 = false;
      }

      if (this.mcf_8 != null) {
        if (this.mcf_8 == "true") {
          if (this.mcf_8_remark != "") {
            case8 = true;
          }
        } else {
          this.mcf_8_remark = "";
          case8 = true;
        }
      }

      if (case7 && case8) {
        this.step3 = true;
      } else {
        this.step3 = false;
      }
    },
    GotoStepDialog3() {
      this.stepdialog = 3;
      this.GotoFocusStep('focusStep3')
    },

    GotoStepDialog4() {
      this.stepdialog = 4;
      this.GotoFocusStep('focusStep4')
    },

    async SaveToDB() {
      try {
        let q = {};
        q.p_id = this.$route.params.id;

        let res = await feathersClientUOA
          .service("mubadala-covid-form-2")
          .find({ query: q });

        if (res.total == 1) {
          let mcf_id = res.data[0].mcf_id;
          let dataUpdate = {
            mcf_1_a: this.mcf_1_a,
            mcf_1_b: this.mcf_1_b,
            mcf_1_c: this.mcf_1_c,
            mcf_1_d: this.mcf_1_d,
            mcf_1_e: this.mcf_1_e,
            mcf_1_f: this.mcf_1_f,
            mcf_1_g: this.mcf_1_g,
            mcf_1_h: this.mcf_1_h,
            mcf_1_i: this.mcf_1_i,
            mcf_1_j: this.mcf_1_j,
            mcf_2: this.mcf_2,
            mcf_2_remark: this.mcf_2_remark,
            mcf_3: this.mcf_3,
            mcf_3_remark: this.mcf_3_remark,
            mcf_4: this.mcf_4,
            mcf_5: this.mcf_5,
            mcf_5_vaccine_name: this.mcf_5_vaccine_name.vc_name,
            mcf_5_vaccine_date: this.mcf_5_vaccine_date,
            mcf_6: this.mcf_6,
            mcf_6_remark: this.mcf_6_remark,
            mcf_7_a: this.mcf_7_a,
            mcf_7_b: this.mcf_7_b,
            mcf_7_c: this.mcf_7_c,
            mcf_7_d: this.mcf_7_d,
            mcf_7_e: this.mcf_7_e,
            mcf_7_f: this.mcf_7_f,
            mcf_7_g: this.mcf_7_g,
            mcf_7_h: this.mcf_7_h,
            mcf_8: this.mcf_8,
            mcf_8_remark: this.mcf_8_remark,
            mcf_consent: true,
            mcf_status: "Wait",
          };

          await feathersClientUOA.service("mubadala-covid-form-2").patch(mcf_id, dataUpdate);

          this.dialogConfirm = false;
          this.dialogSaveComplete = true;
        }
      } catch (e) { }
    },

    GotoFocusStep(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    BackStepDialog(step) {
      if (step == 2) {
        this.GotoFocusStep('focusStep2')
      }
      if (step == 3) {
        this.GotoFocusStep('focusStep3')
      }
      this.stepdialog = step;
    }
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.t-company {
  font-size: 12px;
}
</style>>
